import React, {FC} from 'react';
import Text from "../../components/Text/Text";
import FlexColumn from "../../components/Column/FlexColumn";

const Banner: FC = () => {
    return (
        <FlexColumn
            className={'phone:w-[23rem] phone:h-[14rem] tablet:w-[45rem] tablet:h-[25rem] laptop:w-[60rem] laptop:h-[30rem] mt-32 w-[85rem] h-[38.1875rem] justify-center items-center bg-center bg-no-repeat bg-cover relative bg-[url("assets/images/banner-1.svg")]'}>
            <Text
                className={'phone:text-[1rem] phone:mb-6 phone:w-[20rem] tablet:text-[2rem] tablet:w-[45rem] laptop:text-[2.5rem] laptop:w-[60rem] mb-12 absolute bottom-0 uppercase text-white font-[Nunito-Semibold] text-[3.125rem] text-center w-[60rem]'}>
                ALLSHARING предлагает решения в разных ситуациях
            </Text>
        </FlexColumn>
    );
};

export default Banner;