import React, {FC} from 'react';
import Text from "../Text/Text";
import FlexRow from "../Row/FlexRow";
import PartnerCard from "../Cards/PartnerCard/PartnerCard";
import FlexColumn from "../Column/FlexColumn";

const Support: FC = () => {
    const partners = [
        'images/partner1.svg',
        'images/partner2.svg',
        'images/partner3.svg',
    ]


    return (
        <FlexColumn className={'phone:mt-16 phone:px-6 mt-32 desktop:self-start items-center self-center'}>
            <Text className={'phone:text-[1.8125rem] mb-8 text-primary text-[3.125rem] font-[Nunito-Semibold] uppercase'}>
                Нас поддерживают
            </Text>
            <FlexRow className={'phone:items-baseline phone:flex-col phone:space-y-6 space-x-6 items-center tablet:flex-col tablet:items-baseline tablet:space-y-12'}>
                {partners.map((it, idx) =>
                    <PartnerCard imgSrc={it}/>
                )}
            </FlexRow>
        </FlexColumn>
    );
};

export default Support;